.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.sectionWrapper {
  margin-bottom: 40px;
}

.sectionTitle {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.slide {
  position: relative;
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    transform: scale(0.97);
  }
}

.slideImage {
  width: 100%;
  object-fit: contain;
}

.slideTitle {
  font-size: 22px;
  font-weight: 700;
}
