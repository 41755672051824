.wrapper {
  height: calc(100vh - var(--topbarHeight));
  width: 100%;
  background-image: url('../../../../assets/biketribe-brandImage-2560.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25%;
  color: white;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    text-align: center;
    width: 100%;
  }
}
